import axios from 'axios';
var ItemsService = /** @class */ (function () {
    function ItemsService() {
    }
    ItemsService.prototype.list = function (limit) {
        if (limit === void 0) { limit = 1; }
        return axios.post('/api/ivy-items/v1/items/?limit=' + limit, {
            properties: {}
        }, {
            withCredentials: true
        });
    };
    ItemsService.prototype.find = function (query, limit) {
        if (limit === void 0) { limit = 1; }
        return axios.post('/api/ivy-items/v1/items/?limit=' + limit + '&reference=' + query, { 'properties': {} }, {
            withCredentials: true
        });
    };
    ItemsService.prototype.listConsolidated = function (limit) {
        if (limit === void 0) { limit = 1; }
        return axios.post('/api/ivy-items/v1/consolidated-items/?limit=' + limit, {
            properties: {}
        }, {
            withCredentials: true
        });
    };
    ItemsService.prototype.findConsolidated = function (query, limit) {
        if (limit === void 0) { limit = 1; }
        return axios.post('/api/ivy-items/v1/consolidated-items/?limit=' + limit, query, {
            withCredentials: true
        });
    };
    return ItemsService;
}());
export default new ItemsService();
